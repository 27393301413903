<template>
  <div id="staff-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ YearName }} - {{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-container class="py-0" v-if="floatButtonFlag">
        <v-speed-dial
        v-if="floatButtonFlag"
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <!-- <v-btn
            fab
            dark
            small
            color="indigo"
            @click.prevent="addRecordPrompt = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn> -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                dark
                small
                color="indigo"
                @click.prevent="addRecordPrompt = true"
                v-bind="attrs"
                v-on="on"
              >
                <!-- <v-icon>mdi-plus</v-icon> -->Assign member
              </v-btn>
            </template>
            <span>Assign member</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                dark
                small
                color="green"
                @click.prevent="OpenNewMemberDialog(1)"
                v-bind="attrs"
                v-on="on"
                v-if="AddJcrtBtnFlag"
              >
                <!-- <v-icon>mdi-plus</v-icon> -->Add Jcrt member
              </v-btn>
            </template>
            <span>Add Jcrt member</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                dark
                small
                color="blue"
                @click.prevent="OpenNewMemberDialog(2)"
                v-bind="attrs"
                v-on="on"
              >
                <!-- <v-icon>mdi-plus</v-icon> -->Add JrJc member
              </v-btn>
            </template>
            <span>Add JrJc member</span>
          </v-tooltip>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
        </v-speed-dial>
      </v-container>
      <v-dialog v-model="addRecordPrompt" persistent scrollable>
        <lgb-team-members-modify
          pageDescription="Assign LGB members"
          :addRecordPrompt="addRecordPrompt"
          :ZoneCode="ZoneCode"
          :LomCode="LomCode"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></lgb-team-members-modify>
      </v-dialog>

      <v-dialog v-model="editRecordPrompt" persistent scrollable>
        <lgb-team-members-edit
          pageDescription="Edit LGB members"
          :editRecordPrompt="editRecordPrompt"
          :recordData="selectedData"
          @hideEditRecordPrompt="hideEditRecordPrompt"
          v-if="editRecordPrompt"
        ></lgb-team-members-edit>
      </v-dialog>

      <v-dialog v-model="createRecordPrompt" persistent scrollable>
        <create-new-member
          :pageDescription="NewMemberPageDescription"
          :MemberType="NewMemberType"
          :addRecordPrompt="createRecordPrompt"
          @hideAddRecordPrompt="hideCreateRecordPrompt"
          v-if="createRecordPrompt"
        ></create-new-member>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0" v-if="ResultFlag && rows.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-row wrap>
          <v-col cols="12" md="12" align="center">
            <v-btn
              v-if="refreshButtonFlag"
              elevation="30"
              shaped
              tile
              small
              color="#9370d8"
              class="font-size-h6 white--text"
              @click.prevent="refreshPageData"
            >
              Refresh
            </v-btn>
            <v-btn
            elevation="30"
            shaped
            tile
            small
            color="#66cd00"
            class="font-size-h6 my-3 mr-3 white--text"
            @click.prevent="addRecordPrompt = true"
          >
            Assign Member
          </v-btn>
          </v-col>
        </v-row>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} members found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br /> -->

              <v-data-iterator
                :items="rows"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.MemberId"
                      cols="12" sm="6" md="4" lg="4"
                    >
                      <v-card raised hover ripple elevation="25">
                        <v-card-title>
                          {{ item.MonthTxt }}
                        </v-card-title>
                        <v-card-text>
                          <v-avatar size="200">
                            <img
                              :src="item.ProfilePic"
                              :alt="item.MemberFullname"
                            />
                          </v-avatar>
                          <h3>{{ item.MemberFullname }}</h3>
                          <h3 class="text-primary">
                            {{ item.DesignationName }}
                          </h3>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="red"
                            class="font-size-h6 white--text"
                            @click="editData(item, e)"
                            v-if="item.DesignationId != 4 && editFlag"
                          >
                            <v-icon>mdi-pencil</v-icon> Edit
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
              <!-- <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="tableSelectFlag1"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.ActiveStatus == 2"
                      >
                        mdi-clipboard-text-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                </template>
              </v-data-table> -->
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import LgbTeamMembersModify from "@/view/pages/erp/team-members/lgb/LgbTeamMembersModify.vue";
import LgbTeamMembersEdit from "@/view/pages/erp/team-members/lgb/LgbTeamMembersEdit.vue";
import CreateNewMember from "@/view/pages/erp/team-members/new-lgb/CreateNewMember.vue";

export default {
  mixins: [common],
  components: {
    LgbTeamMembersModify,
    LgbTeamMembersEdit,
    CreateNewMember,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      refreshButtonFlag: false,
      floatButtonFlag: false,

      rows: [],
      tableSelectFlag1: false,
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      editFlag: false,

      createRecordPrompt: false,
      NewMemberType: "",
      NewMemberPageDescription: "",

      selectedData: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      AddJcrtBtnFlag: false,

      zone: {},
      lom: {},

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      FromZoneCodeRules: [(v) => !!v || "Zone is required"],
      FromZoneCode: "",
      FromZoneCodeOptions: [],
      FromZoneCodeOptionsLoading: false,

      FromLomCodeRules: [(v) => !!v || "LOM is required"],
      FromLomCode: "",
      FromLomCodeOptions: [],
      FromLomCodeOptionsLoading: false,

      ToZoneCodeRules: [(v) => !!v || "Zone is required"],
      ToZoneCode: "",
      ToZoneCodeOptions: [],
      ToZoneCodeOptionsLoading: false,

      ToLomCodeRules: [(v) => !!v || "LOM is required"],
      ToLomCode: "",
      ToLomCodeOptions: [],
      ToLomCodeOptionsLoading: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearHalfId: "",
      YearHalfIdRules: [(v) => !!v || "Year Half is required"],
      YearHalfIdOptions: [
        { value: "", text: "--Select Half--" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log({ ZoneCode });
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lgb",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
    },
    OpenNewMemberDialog(id) {
      console.log("OpenNewMemberDialog called id=" + id);
      var NewMemberType = "";
      var NewMemberPageDescription = "";
      switch (id) {
        case 1:
          // Jayceert Wing

          NewMemberType = 8;
          NewMemberPageDescription = "Add member for Jayceert Wing";
          break;

        case 2:
          // Junior Jaycee Wing

          NewMemberType = 9;
          NewMemberPageDescription = "Add member for Junior Jaycee Wing";
          break;

        default:
          break;
      }
      console.log(
        "NewMemberType=" +
          NewMemberType +
          ", NewMemberPageDescription=" +
          NewMemberPageDescription
      );
      this.NewMemberType = NewMemberType;
      this.NewMemberPageDescription = NewMemberPageDescription;
      this.createRecordPrompt = true;
    },
    hideCreateRecordPrompt() {
      console.log("hideCreateRecordPrompt called");
      this.createRecordPrompt = false;
      // this.getTableRecords();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.getTableRecords();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.getTableRecords();
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.editRecordPrompt = true;
        /*
        var url = "/members/subscription/assign";
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
        */
      } else {
        var message = "Kindly select one member to edit";
        this.toast("error", message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = this.$session.get("LomId");
      LomCode = LomCode == (null || undefined) ? "" : LomCode;
      console.log("LomCode=" + LomCode);

      if (LomCode != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/lists";
        var upload = {
          UserInterface: 1,
          OrganizationTypeId: 3,
          LomCode: LomCode,
          YearCode: this.JciYearCode,
          ExceptDesignationId: [1, 5, 7],
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.rows = records.TableData;
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableSelectFlag1 = records.TableSelectFlag;
              thisIns.editFlag = records.TableEditFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Your session is expired. Kindly login again. ";
        console.log("error=" + message);
      }
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.CurrentYearId;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: UserInterface,
          additional: {
            MembershipId: this.MembershipId,
            LomCode: this.FromZoneCode,
            LomCode: this.FromLomCode,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records;
              thisIns.zone = response.data.zone;
              thisIns.lom = response.data.lom;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#staff-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>