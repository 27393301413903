<template>
  <div id="preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageDescription }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container class="py-0">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-card-title>
              <span class="headline">{{ pageDescription }}</span>
            </v-card-title>
            <v-card-text>
              <small>*indicates required field</small>
              <v-container>
                <v-row wrap>
                  <!-- <v-col cols="12" sm="6" md="3" lg="3">
                    <label>*Member type</label>
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="MemberTypeOptionsLoading"
                      :items="MemberTypeOptions"
                      :rules="MemberTypeRules"
                      v-model="MemberType"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>*First Name</label>
                    <v-text-field
                      v-model="FirstName"
                      :rules="FirstNameRules"
                      placeholder="First Name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>Middle Name</label>
                    <v-text-field
                      v-model="MiddleName"
                      :rules="MiddleNameRules"
                      placeholder="Middle Name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>Last Name</label>
                    <v-text-field
                      v-model="LastName"
                      :rules="LastNameRules"
                      placeholder="Last Name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <label>*Street 1</label>
                    <v-text-field
                      v-model="Street1"
                      :rules="Street1Rules"
                      placeholder="Street 1"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="8" lg="8">
                    <label>Street 2</label>
                    <v-text-field
                      v-model="Street2"
                      :rules="Street2Rules"
                      placeholder="Street 2"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>Area</label>
                    <v-text-field
                      v-model="Area"
                      :rules="AreaRules"
                      placeholder="Area"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>*State</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="StateIdOptionsLoading"
                      :items="StateIdOptions"
                      :rules="StateIdRules"
                      v-model="StateId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>*District</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="DistrictIdOptionsLoading"
                      :items="DistrictIdOptions"
                      :rules="DistrictIdRules"
                      v-model="DistrictId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>*City</label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="CityIdOptionsLoading"
                      :items="CityIdOptions"
                      :rules="CityIdRules"
                      v-model="CityId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>*Pincode</label>
                    <v-text-field
                      v-model="Pincode"
                      placeholder="Pincode"
                      type="number"
                      :rules="PincodeRules"
                      :counter="6"
                      v-mask="'######'"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>Mobile Number</label>
                    <v-text-field
                      v-model="MobileNo"
                      :rules="MobileNoRules"
                      placeholder="Mobile Number"
                      type="number"
                      :counter="10"
                      v-mask="'##########'"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4">
                    <label>Email Id</label>
                    <v-text-field
                      v-model="EmailId"
                      :rules="EmailIdRules"
                      placeholder="Email Id"
                      type="email"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <!-- <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Close
                    </v-btn> -->
                    <v-btn
                      type="submit"
                      :disabled="!valid1"
                      @click.prevent="confirmAlert"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="
                        btn btn-primary
                        font-size-h6
                        px-15
                        py-4
                        my-3
                        mr-3
                        white--text
                      "
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
    MemberType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      rows: [],
      member: {},
      ResultFlag: false,

      /*
      MemberTypeRules: [(v) => !!v || "Member Type is required"],
      MemberType: "",
      MemberTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 8, text: "Jayceert Wing" },
        { value: 9, text: "Junior Jaycee Wing" },
      ],
      MemberTypeOptionsLoading: false,
      */
      FirstName: "",
      FirstNameRules: [(v) => !!v || "First Name is required"],

      MiddleName: "",
      MiddleNameRules: [],

      LastName: "",
      LastNameRules: [],

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      Area: "",
      AreaRules: [],

      MobileNo: "",
      MobileNoRules: [
        // (v) => !!v || "Mobile No is required"
      ],

      EmailId: "",
      EmailIdRules: [
        // (v) => !!v || "E-mail is required",
        // (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      StateIdRules: [(v) => !!v || "State is required"],
      StateId: "",
      StateIdOptions: [],
      StateIdOptionsLoading: false,

      DistrictIdRules: [(v) => !!v || "District is required"],
      DistrictId: "",
      DistrictIdOptions: [],
      DistrictIdOptionsLoading: false,

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,
    };
  },
  computed: {},
  watch: {
    StateIdOptions: function () {
      console.log("watch StateIdOptions");
      this.LoadingFlag = false;
      this.StateIdOptionsLoading = false;
    },
    StateId: function () {
      console.log("watch StateId");
      this.LoadingFlag = true;
      this.DistrictIdOptionsLoading = true;
      this.getDistrictIdOptions(
        "DistrictId",
        "DistrictIdOptions",
        this.StateId
      );
    },
    DistrictIdOptions: function () {
      console.log("watch DistrictIdOptions");
      this.LoadingFlag = false;
      this.DistrictIdOptionsLoading = false;
    },
    DistrictId: function () {
      console.log("watch DistrictId");
      this.LoadingFlag = true;
      this.CityIdOptionsLoading = true;
      this.getCityIdOptions("CityId", "CityIdOptions", this.DistrictId);
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.LoadingFlag = false;
      this.CityIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      // this.$emit('Member', this.NewMember);
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      console.log(
        "MemberType=" + this.MemberType + ", type=" + typeof this.MemberType
      );
      this.StateIdOptionsLoading = true;
      this.getStateIdOptions("StateId", "StateIdOptions");
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    getStateIdOptions(source, destination) {
      console.log("getStateIdOptions called");
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictIdOptions(source, destination, StateId) {
      console.log("getDistrictIdOptions called StateId=" + StateId);
      if (source != "" && destination != "" && StateId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityIdOptions(source, destination, DistrictId) {
      console.log("getCityIdOptions called DistrictId=" + DistrictId);
      if (source != "" && destination != "" && DistrictId != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          UserInterface: 1,
          MemberType: this.MemberType,
          FirstName: this.FirstName,
          MiddleName: this.MiddleName,
          LastName: this.LastName,
          Street1: this.Street1,
          Street2: this.Street2,
          Area: this.Area,
          State: this.StateId,
          District: this.DistrictId,
          City: this.CityId,
          Pincode: this.Pincode,
          MobileNumber: this.MobileNo,
          EmailId: this.EmailId,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.ResultFlag = flag;
              thisIns.resetForm();
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.member = {};
  },
};
</script>
